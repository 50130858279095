import apps from "./apps";
import pages from "./pages";
import main from "./main";
import components from "./components";
import admin from "./admin";
import user from "./user";
import production_coordinator from "./production-coordinator";

let navigation = [];
if (localStorage.getItem("is_admin") == 1) {
  navigation = [...admin]; //...production_coordinator
} else {
  navigation = [...user];
}

export default navigation;
