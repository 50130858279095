import Api from "./Api";

export default {
  Login(payload) {
    return Api().post(`/auth/login`, payload);
  },
  AddUser(payload) {
    return Api().post(`/user/addUser`, payload);
  },

  GetAllUsers(payload, search = "") {
    let user_active = payload.user_active ? payload.user_active : 1;
    return Api().get(
      `/user/getUser?user_active=${user_active}&search=${search}`
    );
  },
  GetUserCompliense() {
    return Api().get(`/document_compliance/getDocumentCompliance?type=1`);
  },

  AcceptUserCompliense(payload) {
    return Api().post(`/document_compliance/acceptUserCompliance`, payload);
  },
  getUserAcceptedCompliance() {
    return Api().get(`/document_compliance/getUserAcceptedCompliance`);
  },
  getUserProfile() {
    return Api().get(`user/getUserProfile`);
  },

  getUsersByTreeID(tree_id) {
    return Api().get(`/user/getUsersByTreeID?tree_id=${tree_id}`);
  },
  getDBInfo(project_id = null, day = 0) {
    return Api().get(`/user/getDBInfo?project_id=${project_id}&day=${day}`);
  },
  SendPasswordResetOtp(payload) {
    return Api().post(`/auth/resetPasswordOtp`, payload);
  },
  verifyUserOtp(payload) {
    return Api().post(`/auth/verifyUserOtp`, payload);
  },

  resetUserPassword(payload) {
    return Api().post(`/auth/resetUserPassword`, payload);
  },

  ImportExcel(file) {
    return Api().post(`/user/importExcel`, file);
  },
  ChangePassword(payload) {
    return Api().post("/user/changePassword", payload);
  },
  GetUsersByProjectAndDesignation(project_id, designation_id) {
    return Api().get(
      `/user/getUsersByProjectAndDesignation?project_id=${project_id}&designation_id=${designation_id}`
    );
  },

  // GetSingleUserDetails(payload) {
  //     return Api().get(`users/${payload.user_id}`);
  // },

  // VerifyUser(payload) {
  //     return Api().post(`/verifyUser`, payload);
  // },
  // GetUserDetails() {
  //     return Api().get(`/user/user-details`);
  // },
  // SaveGuest(payload) {
  //     return Api().post(`/guest`, payload);
  // },
  // GetUserProfile() {
  //     return Api().get(`/user/user-profile`);
  // },
  // UpdateUserProfile(payload) {
  //     return Api().put(`/user/user-profile`, payload)
  // },
  // GetUserBookings() {
  //     return Api().get(`/user/user-bookings`);
  // },
  // ResetPassword(payload) {
  //     return Api().post(`/user/reset-password`, payload)
  // },
  // EmailTicket(payload) {
  //     return Api().post(`/user/email-ticket`, payload);
  // },
  // SocialLogin(payload) {
  //     return Api().post(`/social-login`, payload);
  // }
};
