import { Calendar, People, Buy } from "react-iconly";
import { MdDashboardCustomize } from "react-icons/md";
import { AiFillMediumCircle } from "react-icons/ai";

const admin = [
  {
    id: "dashboard",
    title: "Dashboard",
    icon: <MdDashboardCustomize set="curved" />,
    navLink: "/home/dashboard",
  },
  {
    id: "master",
    title: "Master",
    icon: <AiFillMediumCircle set="curved" />,
    navLink: "/home/masters",
  },
  {
    id: "contracts",
    title: "Contracts",
    icon: <People set="curved" />,
    navLink: "/home/contracts",
  },
  {
    id: "view-contracts",
    title: "View Contracts",
    icon: <People set="curved" />,
    navLink: "/home/view-contracts",
  },
  // {
  //   id: "change password",
  //   title: "Change Password",
  //   icon: <People set="curved" />,
  //   navLink: "/home/change password",
  // },
  // {
  //   id: "designation",
  //   title: "Designation",
  //   icon: <Calendar set="curved" />,
  //   navLink: "/home/designation",
  // },

  // {
  //   id: "users",
  //   title: "Users",
  //   icon: <People set="curved" />,
  //   navLink: "/home/users",
  // },
  // {
  //   id: "approval-tree",
  //   title: "Approval Tree",
  //   icon: <Buy set="curved" />,
  //   navLink: "/home/approval-tree",
  // },
  // {
  //   id: "projects",
  //   title: "Projects",
  //   icon: <Buy set="curved" />,
  //   navLink: "/home/projects",
  // },
  //   {
  //     id: "designationn",
  //     title: "Designationn",
  //     icon: <Calendar set="curved" />,
  //     navLink: "/home/designationn",
  //   },
  // {
  //   id: "policies-and-forms",
  //   title: "Policies And Forms",
  //   icon: <Calendar set="curved" />,
  //   navLink: "/home/policies-and-forms",
  // },
  // {
  //   id: "documents",
  //   title: "Documents",
  //   icon: <People set="curved" />,
  //   navLink: "/main/documents",
  // },
];

export default admin;
