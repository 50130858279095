import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import UserService from "../../../apiServices/UserService";
import { Menu, Dropdown, Col, Avatar, Image, Button, notification } from "antd";
import menuImg from "../../../assets/images/pages/profile/menu-img.svg";
import avatar from "../../../assets/images/memoji/memoji-1.png";
import {
  User,
  People,
  InfoSquare,
  Calendar,
  Discount,
  Logout,
} from "react-iconly";

import {
  RiErrorWarningFill,
  RiCheckboxCircleFill,
  RiCloseFill,
} from "react-icons/ri";

import { useHistory, useLocation } from "react-router-dom";

import avatarImg from "../../../assets/images/memoji/memoji-1.png";
import { BASEURL } from "../../../config";

const TEMP_TOKEN = localStorage.getItem("temp_token");

export default function HeaderUser() {
  const customise = useSelector((state) => state.customise);
  const history = useHistory();
  const location = useLocation();

  const logoutUser = () => {
    localStorage.clear();
    history.push("/login");
  };

  const [profileData, setProfileData] = useState({});
  useEffect(() => {
    console.log("location => ", location);
    getUserProfileData();
  }, []);

  // Notifications Start
  const openSuccessNotification = (message) => {
    notification.open({
      message: "Success",
      description: message,
      icon: <RiCheckboxCircleFill style={{ color: "#00F7BF" }} />,
      closeIcon: (
        <RiCloseFill className="remix-icon da-text-color-black-80" size={24} />
      ),
    });
  };

  const openWarningNotification = (message) => {
    notification.open({
      message: "Warning",
      description: message,
      icon: <RiErrorWarningFill style={{ color: "#FFC700" }} />,
      closeIcon: (
        <RiCloseFill className="remix-icon da-text-color-black-80" size={24} />
      ),
    });
  };
  // Notifications End

  const getUserProfileData = () => {
    UserService.getUserProfile()
      .then((response) => {
        if (response.data.status == true) {
          setProfileData(response.data.data[0]);
        } else {
          openWarningNotification(response.data.message);
        }
      })
      .catch((err) => openWarningNotification(err.message));
  };
  const menu = (
    <Menu theme={customise.theme == "light" ? "light" : "dark"}>
      {location && location.pathname != "/pages/complience" ? (
        <Menu.Item
          key={0}
          icon={
            <User
              set="curved"
              className="remix-icon da-vertical-align-middle da-text-color-dark-0"
              size={16}
            />
          }
          className="da-text-color-dark-0"
        >
          <Link to="/pages/profile/personel-information">Profile</Link>
        </Menu.Item>
      ) : null}

      {/* <Menu.Item
        key={1}
        icon={
          <People
            set="curved"
            className="remix-icon da-vertical-align-middle da-text-color-dark-0"
            size={16}
          />
        }
        className="da-text-color-dark-0"
      >
        <Link to="/apps/contact">Contact</Link>
      </Menu.Item> */}
      {/* <Menu.Item
        key={2}
        icon={
          <Calendar
            set="curved"
            className="remix-icon da-vertical-align-middle da-text-color-dark-0"
            size={16}
          />
        }
        className="da-text-color-dark-0"
      >
        <Link to="/apps/calendar">Calendar</Link>
      </Menu.Item> */}
      {/* <Menu.Item
        key={3}
        icon={
          <Discount
            set="curved"
            className="remix-icon da-vertical-align-middle da-text-color-dark-0"
            size={16}
          />
        }
        className="da-text-color-dark-0"
      >
        <Link to="/pages/pricing">Pricing</Link>
      </Menu.Item> */}
      {/* <Menu.Item
        key={4}
        icon={
          <InfoSquare
            set="curved"
            className="remix-icon da-vertical-align-middle da-text-color-dark-0"
            size={16}
          />
        }
        className="da-text-color-dark-0"
      >
        <Link to="/pages/faq">FAQ</Link>
      </Menu.Item> */}
      <Menu.Item
        key={5}
        icon={
          <Logout
            set="curved"
            className="remix-icon da-vertical-align-middle da-text-color-dark-0"
            size={16}
          />
        }
        className="da-text-color-dark-0"
        onClick={logoutUser}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Col>
      <Dropdown overlay={menu} placement="bottomLeft">
        <Col className="da-d-flex-center">
          {/* <h4
            style={{
              marginRight: "10px",
              marginBottom: "2px",
              fontWeight: "bold",
            }}
          >
            {profileData.user_name}
          </h4> */}
          <Avatar
            shape="circle"
            size={40}
            src={
              profileData.image
                ? `${BASEURL}/public/uploads/${profileData.image}?token=${TEMP_TOKEN}`
                : avatar
            }
          />
        </Col>
      </Dropdown>
    </Col>
  );
}
