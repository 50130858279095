import { Calendar, People, Buy } from "react-iconly";
const production_coordinator = [
  {
    header: "MAIN",
  },
  {
    id: "policies-and-forms",
    title: "Policies And Forms",
    icon: <Calendar set="curved" />,
    navLink: "/main/policies-and-forms",
  },
  // {
  //   id: "documents",
  //   title: "Documents",
  //   icon: <People set="curved" />,
  //   navLink: "/main/documents",
  // },
  {
    id: "contracts",
    title: "Contracts",
    icon: <Buy set="curved" />,
    navLink: "/main/contracts",
  },
  // {
  //     id: "designation",
  //     title: "Designation",
  //     icon: <Buy set="curved" />,
  //     navLink: "/main/designation"
  // },
];

export default production_coordinator;
