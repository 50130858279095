import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { Button, Badge, Row, Col, Dropdown, Divider, Avatar } from "antd";
import { Notification, Message, Calendar } from "react-iconly";
import { RiMoneyDollarCircleLine, RiTruckLine } from "react-icons/ri";
import OneSignal from "react-onesignal";
import NotificationService from "../../../apiServices/NotificationService";
import "./style.css";
import DropdownButton from "antd/lib/dropdown/dropdown-button";
export default function HeaderNotifications() {
  const direction = useSelector((state) => state.customise.direction);
  const history = useHistory();
  const location = useLocation();
  console.log("history => ", history, "location => ", location);

  const [notificationData, setNotificationData] = useState([]);
  const HOST = window.location.host;
  useEffect(() => {
    NotificationService.GetUserNotification({})
      .then((response) => {
        if (response.data.status == true) {
          //console.log(response.data.data, "jhj");
          setNotificationData(response.data.data);
        }
      })
      .catch((error) => console.log("error => ", error));
  }, []);

  useEffect(() => {
    OneSignal.init({
      appId: "dbfd4951-1143-484e-88c1-e8b6ac77e497",
    });

    OneSignal.on("notificationDisplay", function (event) {
      //  console.log("OneSignal notification displayed:", event);
      NotificationService.GetUserNotification({})
        .then((response) => {
          if (response.data.status == true) {
            //console.log(response.data.data, "jhj");
            setNotificationData(response.data.data);
          }
        })
        .catch((error) => console.log("error => ", error));
    });
  }, []);

  const notificationClicked = (notification) => {
    history.push(`/contract/${notification.contract_id}`);
  };

  const notificationMenu = (
    <div className="da-py-16 da-bg-color-black-0 da-bg-color-dark-100 da-border-color-black-40 da-border-color-dark-80 da-border-radius da-border-1">
      <div className="da-px-16">
        <Row align="middle" justify="space-between">
          <Col className="da-p1-body da-font-weight-500 da-text-color-black-100 da-text-color-dark-10 da-text-color-dark-0 da-mr-64">
            Notifications
          </Col>

          <Col className="da-bg-color-primary-1 da-border-radius-full da-badge-text da-text-color-black-0 da-py-4 da-px-6 da-ml-24">
            {notificationData.length}
          </Col>
        </Row>
      </div>
      {notificationData && notificationData.length > 0
        ? notificationData.map((item, index) => (
            <div
              className="notification"
              key={index}
              onClick={() => notificationClicked(item)}
            >
              <Divider className="da-my-16 da-mx-0 da-bg-color-black-40 da-bg-color-dark-80" />

              <div
                className="da-px-16"
                //    style={{ maxHeight: "20px", overflowY: "scroll" }}
              >
                <Row align="middle">
                  <Col className="da-mr-8">
                    <Avatar
                      size={32}
                      icon={
                        <Message
                          size={17}
                          className="da-text-color-primary-1 remix-icon"
                        />
                      }
                      className="da-d-flex-center-full"
                    />
                  </Col>

                  <Col>
                    <span className="da-d-block da-w-100 da-mb-4 da-badge-text da-font-weight-500 da-text-color-black-100 da-text-color-dark-10">
                      {item.notification_title}
                    </span>

                    <span className="da-d-block da-input-description da-font-weight-500 da-text-color-black-80 da-text-color-dark-40">
                      {item.notification_msg}
                    </span>
                  </Col>
                </Row>
              </div>
            </div>
          ))
        : null}
      {/* { notificationData && notificationData.length>0 
&& notificationData.map(item)=>  } */}

      {/* <Divider className="da-my-16 da-mx-0 da-bg-color-black-40 da-bg-color-dark-80" /> */}

      {/* <div className="da-px-16">
        <Button
          type="primary"
          block
        >
          Clear all notifications
        </Button>
      </div> */}
    </div>
  );

  return (
    <Col className="da-d-flex-center da-mr-sm-12 da-mr-16">
      <Button
        type="text"
        icon={
          <Dropdown
            trigger={"click"}
            overlay={notificationMenu}
            placement="bottomRight"
            // style={{ innerHeight: "20px" }}
          >
            <div className="da-position-relative">
              <div
                className="da-position-sticky da-bg-color-primary-1 da-border-radius-full da-badge-text da-text-color-black-0 da-py-4 da-px-6 da-ml-24 "
                style={
                  direction == "rtl"
                    ? { left: -5, top: -5 }
                    : { right: -5, top: -5 }
                }
              >
                {notificationData.length}
              </div>

              <Notification set="curved" className="da-text-color-black-60" />
            </div>
          </Dropdown>
        }
      />
    </Col>
  );
}
