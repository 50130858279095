import { Calendar, People, Buy } from "react-iconly";
const ep = [
  // {
  //   header: "MAIN",
  // },
  {
    id: "dashboard",
    title: "Dashboard",
    icon: <People set="curved" />,
    navLink: "/home/dashboard",
  },
  {
    id: "policies-and-forms",
    title: "Policies And Forms",
    icon: <Calendar set="curved" />,
    navLink: "/home/policies-and-forms",
  },
  {
    id: "view-contracts",
    title: "View Contracts",
    icon: <People set="curved" />,
    navLink: "/home/view-contracts",
  },
  // {
  //   id: "documents",
  //   title: "Documents",
  //   icon: <People set="curved" />,
  //   navLink: "/main/documents",
  // },
  // {
  //   id: "contracts",
  //   title: "Contracts",
  //   icon: <Buy set="curved" />,
  //   navLink: "/main/contracts",
  // },
  // {
  //     id: "designation",
  //     title: "Designation",
  //     icon: <Buy set="curved" />,
  //     navLink: "/main/designation"
  // },
  {
    id: "contracts",
    title: "Contracts",
    icon: <Buy set="curved" />,
    navLink: "/home/contracts",
  },
];

export default ep;
