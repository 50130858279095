import * as actionTypes from "./baseTypes";
import themeConfig from "../../configs/themeConfig";

const INITIAL_STATE = {
  loading: false,
};

const baseReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};

export default baseReducer;
