import React from "react";
import { Spin, Modal } from "antd";
import "./styles.css";

const CustomLoader = () => {
  return (
    <Modal
      footer={null}
      visible={true}
      mask={false}
      closable={false}
      centered={true}
      bodyStyle={{
        width: 0,
        height: 0,
        padding: 0,
        margin: 0,
      }}
      zIndex={3000}
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "rgba(0, 0, 0, 0.0)",
      }}
    >
      <Spin />
    </Modal>
  );
};

export default CustomLoader;
