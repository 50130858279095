import React, { useEffect } from "react";

import { useSelector } from "react-redux";

import { ConfigProvider } from "antd";

import Router from "./router/Router";
import "./App.css";
import OneSignal from "react-onesignal";

export default function App() {
  const customise = useSelector((state) => state.customise);

  useEffect(() => {
    OneSignal.init({
      appId: "dbfd4951-1143-484e-88c1-e8b6ac77e497",
    });
    OneSignal.on("addListenerForNotificationOpened", (event) => {
      console.log("Onesignal event => ", event);
    });
  }, []);

  return (
    <ConfigProvider direction={customise.direction}>
      <Router />
    </ConfigProvider>
  );
}
